@import '~@styles/breakpoints';

.HeroOuterContainer {
  width: calc(100vw - 9px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @include media('<md') {
    width: calc(100vw - 8px);
  }

  @include media('<=sm') {
    width: 100%;
    position: static;
    margin: 0;
  }
}

.FinanceTerms {
  composes: FinanceTerms from '~@containers/ProductPage/ProductPage.module.scss';

  li {
    @include media('<md') {
      margin-bottom: 0.5rem;
      font-size: 1.4rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media('<md') {
    margin-bottom: 0;
  }
}

.ModalContent {
  // @include media('<md') {
  //   min-width: max-content;
  //   overflow-x: scroll;
  // }
}

.WarrantyContent,
.TermContent {
  overflow-x: auto;
}

.HasInfo,
.HasWarrantyInfo {
  cursor: pointer;

  &::after {
    content: 'i';
    display: inline-block;
    margin: 0 0 0 1rem;
    background: #ddd;
    text-align: center;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    color: #333;
    font-size: 1.3rem;
    cursor: pointer;

    @include media('<md') {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
    }
  }
}

.Hero {
  width: 100%;
}

.Descriptions {
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.MobileDescription {
  font-size: 1.8rem;
  padding: 3% 5%;

  p {
    margin-bottom: 0;
  }
}

.RequestQuoteBtn {
  margin-left: 1rem;

  @include media('<md'){
    margin-left: 0;
    margin-top: 1rem;
  }
}
