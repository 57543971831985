@import '~@styles/breakpoints';

.DefaultPage {
  position: relative;
}

.PostPageContainer {
  display: grid;
  grid-template-areas:
        "LeftBodyContent RightRelated"
        "RelatedProduct RelatedProduct"
        "PromotionTiles PromotionTiles"
        "ProductBrowser ProductBrowser";
  column-gap: 0;
  grid-column-gap: 0;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr;

  @include media('<md') {
    grid-template-areas:
          "LeftBodyContent"
          "PromotionTiles"
          "ProductBrowser"
          "RightRelated"
          "RelatedProduct";
    grid-template-columns: repeat(1, 1fr);
  }

  &.PostFullPageContainer {
    grid-template-areas:
        "LeftBodyContent"
        "RightRelated"
        "RelatedProduct"
        "PromotionTiles"
        "ProductBrowser";
    grid-template-columns: repeat(1, 1fr);
  }
}
