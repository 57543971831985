@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: Title from '~@styles/sections.module.scss';
}

.List {
  padding: 0;

  @include media('>md') {
    display: grid;
    margin-top: 1.5rem;
    grid-gap: 2.5rem 1.65rem;
  }

  @include media('<=md') {
    grid-template-columns: repeat(1, 1fr);
  }

  @include media('<=lg') {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media('>lg') {
    grid-template-columns: repeat(5, 1fr);
    // grid-gap: 5.5rem 2rem;
  }
}

.Item {
  @include media('<md') {
    display: flex;
    flex-direction: row;
  }
}
.CategoryList {
  padding: 0;
  display: grid;
  margin-top: 1.5rem;
  grid-gap: 2rem;

  @include media('<xl') {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.85rem 1.65rem;
  }
  @include media('<=md') {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  @include media('>=xl') {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5rem 1.65rem;
  }
  h2 {
    margin-bottom: 1rem;
  }
  ul {
    li {        
      &.bolder {
        font-weight: bolder;
        font-size: 1.8rem;
      }   
    }
  }
  h3 {
    font-size: 2.9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--text-color);
    line-height: 1.1em;
  }
  p {
    margin-bottom: 0;
  }
  .Item {
    
      display: flex;
      flex-direction: column;
      border-bottom: 0;    

  }
}
