@import '~@styles/breakpoints';

.Section {
  width: 100%;

  @include media('<md') {
    overflow: hidden;
  }
}

.Container {
  padding: 3rem 5%;

  @include media('>=md') {
    padding: 2rem;
  }
  @include media('>lg') {
    padding: 3rem 6rem;
  }
  @include media('<md') {
    padding: 1.5rem 5%;
  }
}

.Title {
  composes: H2 FontBold from '~@styles/typography.module.scss';
}
