@import '~@styles/breakpoints';

.Breadcrumbs {
  margin-bottom: 0;
  padding-bottom: 0;
}

.Category {
  composes: Section from '~@styles/sections.module.scss';
}

.SectionContainer {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: FontBoldItalic H1 from '~@styles/typography.module.scss';
  margin-bottom: 1rem;

  @include media('<md') {
    font-size: 2.9rem;
  }

  @include media('>=md') {
    margin-bottom: 3rem;
  }
}

