@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: Title from '~@styles/sections.module.scss';
}

.List {
  padding: 0;

  @include media('>md') {
    display: grid;
    margin-top: 1.5rem;
    grid-gap: 2.5rem 1.65rem;
  }

  @include media('<=md') {
    grid-template-columns: repeat(1, 1fr);
  }

  @include media('>=lg', '<xl') {
    grid-template-columns: repeat(5, 1fr);
  }

  @include media('>=xl') {
    grid-template-columns: repeat(8, 1fr);
    // grid-gap: 5.5rem 2rem;
  }
}

.Item {
  @include media('<md') {
    display: flex;
    flex-direction: row;
  }
}
.DarkMode{
  .Title{
    color: var(--dark-mode-componnet-title);
  }
}
.TwoColumnsView{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .Info {
    flex: 1;
    display: flex;  
    flex-direction: column;
    justify-content: center;  
    align-items: center;
  }
  .subTitle{   
    font-size: 1.3rem;
    align-items: center;
    font-weight: normal;
    margin-top: 1rem;
  
    @include media('<md') {
      font-size: inherit;
    }
  }
  ul{
    flex : 1 1 0px;
    height: fit-content;
    &.List{
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2.5rem 1.65rem;
    }
  }
  .LargeView{
    flex : 1 1 0px;
    .LTitle{
      font-size: 2.9rem;
    }
    @include media('<md') {
      display: none;
    }
    .Image { 
      width: auto;
      height: auto;
      max-height: 36rem;

    }
    .subTitle{
      //composes: TextNormal from '~@styles/typography.module.scss';
      font-size: 1.6rem;
    
      @include media('<md') {
        font-size: inherit;
      }
    }
    
  }
  @include media('<md') {
    flex-direction: column;
  }
.ImageBox{
  @include media('<md') {
    overflow: hidden;
  }
}

.Tile{
  @include media('<md'){
padding: 0;
border-bottom: 0;
  }
}
.List{
  @include media('<md'){
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 2rem 1rem;
    display: grid;
  }
}

}

.Tile {
  display: flex;
  color: inherit;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.92);
  padding: 1.5rem;
  cursor: pointer;

  .Image{
    transition: transform .8s;
  }

  @include media('>=md') {
    flex-direction: column;
  }
  @include media('<=md') {
    flex-direction: column;
    border-bottom: 0.2rem solid var(--border-color);
  }

  &:hover,
  &:active {
    .Image{
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
    }
    color: inherit;
    .Title {
      font-size: 1.4rem;
      color: var(--primary-color);
    }
  }
  
 
  .Content {
    display: flex;
    flex: 1;
    flex-direction: column;
  
    @include media('<md') {
      flex: 0 0 50%;
      padding: 0;
      font-size: 1.5rem;
    }
  }
  
  .Info {
    flex: 1;    
  }
  
  
}
.Image { 
  width: auto;
  height: auto;
}
.H3 {
  composes: TextNormal FontBold from '~@styles/typography.module.scss';
  margin-bottom: 0;
  text-align: center;
  font-size: 1.4rem;
  color: var(--text-color);
  @include media('<md') {
    font-size: inherit;
  }
}


