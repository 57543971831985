@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: Title from '~@styles/sections.module.scss';
}

.Left {
  float: left;
  width: 70%;

  @include media('<xl') {
    float: none;
    width: 100%;
  }
}

.List {
  display: grid;
  grid-gap: 3rem;
  list-style: none;
  margin: 0 auto;
  padding: 0;

  @include media('>lg') {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media('<=lg') {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media('<md') {
    grid-template-columns: repeat(1, 1fr);
  }
}
