@import '~@styles/breakpoints';

.FinanceTerms {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;

    @include media('>=lg') {
        font-size: 1.6rem;
    }
  }
  .ModalContent {
    @include media('<md') {
      min-width: max-content;
      overflow-x: scroll;
    }
  }
  .HasInfo::after{
    content: "i";
    display: inline-block;   
    margin: 0 0 0 1rem;
    background: #ddd;    
    text-align: center;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;   
    vertical-align: center;
    color: #333;
    font-size: 1.3rem;
    cursor: pointer;
  }  
  
}
