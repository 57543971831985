@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: Title from '~@styles/sections.module.scss';
}

.List {
  padding: 0;

  @include media('>=md') {
    display: grid;
    margin-top: 1.5rem;
    grid-gap: 2rem;
  }

  @include media('>=md', '<lg') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media('>=lg', '<xl') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media('>=xl') {
    grid-template-columns: repeat(4, 1fr);
    // grid-gap: 5.5rem 2rem;
  }
  list-style: none;
  li > div{   
    text-align: center;
  }


}
.Accessories{
  background: #363636;
  border: 1px solid #5C5C5C;
  overflow: hidden;
  @include media('<md') {
    margin-bottom: 2rem;    
  }
  
}
.Item {
  @include media('<md') {
    display: flex;
    flex-direction: column;
  }
  
}
