@import '~@styles/breakpoints';

.HomePage {
  --app-bar-height: 9rem;
  position: relative;
}

.Hero {
  height: calc(100vh - var(--nav-height) - var(--app-bar-height));
}

.SectionTitle {
  composes: H2 from '~@styles/typography.module.scss';
}
