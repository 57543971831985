@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
  clear: both;
  grid-area: RelatedProduct;
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: Title from '~@styles/sections.module.scss';
}

.List {
  padding: 0;

  @include media('>md') {
    display: grid;
    margin-top: 1.5rem;
    grid-gap: 2rem;
  }
  @include media('<=md') {
    grid-template-columns: repeat(1, 1fr);
  }
  @include media('<=lg') {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media('>lg') {
    grid-template-columns: repeat(5, 1fr);
  }
}

.PostList {
  padding: 0;

  @include media('>=md') {
    display: grid;
    margin-top: 1.5rem;
    grid-gap: 2rem;
  }
  @include media('>=md', '<lg') {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media('>=lg', '<xl') {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media('>=xl') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Item {
  @include media('<md') {
    display: flex;
    flex-direction: column;
  }
}
