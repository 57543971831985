@import '~@styles/breakpoints';

$mobileItemHeight: 48px;
$desktopItemHeight: 24px;

.ApplicationsBar {
  background-color: var(--bg-color);
  width: 100%;
  height: var(--app-bar-height);
}

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include media('<md') {
    flex-direction: column;
    padding: 5%;
  }
}

.Prompt {
  composes: TextSmall from '~@styles/typography.module.scss';
  margin-bottom: 0;
}

.MenuContainer {
  position: relative;

  @include media('>=md') {
    margin-left: 1.5rem;
  }
}

.MenuWrapper {
  position: relative;
  height: 7.2rem;
  min-width: 33rem;
  overflow: hidden;
  flex: 0 0 auto;

  @include media('<md') {
    height: 4.8rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 40%;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &::before {
    top: 0;
    background: linear-gradient(to bottom, #fff, rgba(#fff, 0));
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, #fff, rgba(#fff, 0));
  }
}

.ApplicationsMenu {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @include media('>=md') {
    padding: 2.4rem 0;
  }
}

.ApplicationsMenuItem {
  position: relative;
  composes: FontCondensed from '~@styles/typography.module.scss';
  display: flex;
  align-items: center;
  height: $mobileItemHeight;
  padding: 0.5rem 4rem 0.5rem 1em;
  color: #c1c1c1;
  text-transform: uppercase;
  font-size: 1.8rem;

  @include media('>=md') {
    height: $desktopItemHeight;
    font-size: 2rem;
  }

  &.Active {
    color: var(--text-color);
  }
}

.SelectArrow {
  position: absolute;
  color: var(--primary-color);
  transform: rotate(90deg);
  width: 2rem;
  height: 2rem;
  right: 0;
}

.MenuSlideout {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;

  @include media('>=md') {
    position: absolute;
    bottom: 100%;
    box-shadow: rgb(29 29 29 / 40%) 0 -0.5em 0.5em;
    overflow: hidden;
  }

  :global(.swiper-scrollbar-drag) {
    background: var(--primary-color);
  }
}

.SlideoutContainer {
  @include media('<md') {
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    height: calc(100vh - var(--nav-height));
  }

  @include media('>=md') {
    height: 24rem;
  }
}

.SlideoutSwiper {
  width: 100%;

  @include media('<md') {
    flex: 1;
  }

  @include media('>=md') {
    height: 100%;
    padding-right: 1.2rem;
  }
}

.SlideoutSlide {
  height: auto;
}

.SlideoutList {
  display: block;
  padding: 0;
  margin: 0;
}

.SlideoutItem {
  height: 3.5rem;
  list-style: none;
}

.SlideoutLink {
  composes: FontCondensed from '~@styles/typography.module.scss';
  padding: 0 1.5rem;
  border-bottom: 0.1rem solid rgba(193, 193, 193, 0.3);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  color: #000;
  text-transform: uppercase;
  font-size: 2rem;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
}

.SlideoutArrow {
  width: 2rem;
  height: 2rem;
  transform: rotate(180deg);
  color: #fff;
}

.SlideoutText {
  text-align: center;
}

.SlideoutClose {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 0;
  border: 0;
  background: none;
}

.SlideoutCloseIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 1.5rem;
}

:export {
  mobileItemHeight: $mobileItemHeight;
  desktopItemHeight: $desktopItemHeight;
}
