@import '~@styles/breakpoints';

.HeroOuterContainer {
  width: calc(100vw - 9px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @include media('<md') {
    width: calc(100vw - 9px);
  }
  @include media('<=sm') {
    width: 100%;
    position: static;
    margin: 0;
  }
}

.FullWidth {
  grid-area: HeroFullWidth;
  position: relative;
  width: 100%;
  margin: 0;

  @include media('<md') {
    position: inherit;
  }
}

.Description {
  p {
    font-size: 1.8rem;
  }
}

.MobileDescription {
  padding: 3% 5%;
  p {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}

.Button {
  margin-top: 4rem;
}
