@use "sass:math";
@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
  height: 100%;
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
  height: 100%;
}

.PromotionLeft,
.PromotionRight {
  width: 100%;
  overflow: hidden;

  @include media('>=md') {
    width: 50%;
    display: inline-block;
    height: 40rem;
  }
  @include media('<=md') {
    margin-top: 1rem;
  }
}

.PromotionLeft {
  .Container {
    padding: 0;

    @include media('>=md') {
      padding: 0.5rem 0.25rem 0.5rem 4.5rem;
    }
    @include media('>lg') {
      padding: 0.5rem 0.25rem 0.5rem 6rem;
    }
  }
}

.PromotionRight {
  .Container {
    padding: 0;

    @include media('>=md') {
      padding: 0.5rem 4.5rem 0.5rem 0.25rem;
    }
    @include media('>lg') {
      padding: 0.5rem 6rem 0.5rem 0.25rem;
    }
  }
}

.PromotionWhole {
  width: 100%;

  & >:first-child {
    padding-top: percentage(math.div(math.div(510, 550), 2));

    @include media('<md') {
      padding-top: percentage(math.div(510, 550));
    }
  }
}

.PromotionButton {
  border-radius: 10px;
}
