
@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
}
.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: H2 from '~@styles/typography.module.scss';
}
.List {
  margin: 3rem auto;
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;

  @include media('<=md') {
    margin-left: -5vw;
    margin-right: -5vw;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }

  @include media('>md') {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
  @include media('>xl') {   
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }

  .Tile{
    display: block;
    position: relative;
    z-index: 0;
    a{
      width: 100%;
      height: 100%;
      display: block;
      padding-top: 1.2rem;
      .Image{
        width: auto;
        max-height: 325px;
        height: 100%;
      }
    }
  }
}
